// import { useEffect, useState } from "react";
// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../services/firebaseConfig";

function Bio() {
  // const [textBio, setTextBio] = useState([]);

  // useEffect(() => {

  //   const bioDataFromStorage = sessionStorage.getItem("textBio");
  //   if (bioDataFromStorage) {
  //     setTextBio(JSON.parse(bioDataFromStorage));
  //   } else {
  //     const getBio = async () => {
  //       try {
  //         const bioCollection = collection(db, "minibio");
  //         const querySnapshot = await getDocs(bioCollection);

  //         const bioData = [];

  //         querySnapshot.forEach((doc) => {
  //           const data = doc.data();
  //           bioData.push(data);
  //         });

  //         setTextBio(bioData);
  //         sessionStorage.setItem("textBio", JSON.stringify(bioData));
  //       } catch (error) {
  //         console.error("Erro ao buscar bio:", error);
  //       }
  //     };
  //     getBio();
  //   }
  // }, []);

  return (
    <section className="bio-container">
      {/* {textBio.map((bio, index) => (
        <p key={index}>{bio.text}</p>
      ))} */}
      {/* CONTEUDO DIRETO NO COMPONENTE */}
      <p>
        Cantora, instrumentista e compositora, Júlia
        transita por diversos universos sonoros. Permeia ritmos e línguas, com músicas em espanhol, inglês e
        português, tendo como principais influências sonoras o Indie soul, Indie
        electronic, Mpb e Jazz.
      </p>
    </section>
  );
}

export default Bio;
