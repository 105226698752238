import React, { useState } from "react";
import Modal from "./_modal";

import galleryImage1 from "../assets/photo-bluebird.webp";
import galleryImage2 from "../assets/photo_mercado.webp";
import galleryImage3 from "../assets/photo_chris_mayer.webp";
import galleryImage4 from "../assets/26221010_921851231312009_3472793806554107650_o.webp";

function Photos() {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [touchStartX, setTouchStartX] = useState(null);
  const [isSwiping, setIsSwiping] = useState(false);

  const photos = [
    {
      url: galleryImage1,
    },
    {
      url: galleryImage2,
    },
    {
      url: galleryImage3,
    },
    {
      url: galleryImage4,
    },
  ];

  const openModal = (index) => {
    setSelectedIndex(index);
    setSelectedPhoto(photos[index].url); 
  };

  const closeModal = () => {
    setSelectedIndex(null);
    setSelectedPhoto(null);
  };

  const showNextPhoto = () => {
    if (selectedIndex < photos.length - 1) {
      const newIndex = selectedIndex + 1;
      setSelectedIndex(newIndex);
      setSelectedPhoto(photos[newIndex].url); 
    }
  };

  const showPrevPhoto = () => {
    if (selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      setSelectedIndex(newIndex);
      setSelectedPhoto(photos[newIndex].url); 
    }
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (event) => {
    if (!isSwiping) return;

    const currentX = event.touches[0].clientX;
    const difference = touchStartX - currentX;

    if (difference > 50) {
      showNextPhoto();
      setIsSwiping(false);
    } else if (difference < -50) {
      showPrevPhoto();
      setIsSwiping(false);
    }
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);
  };

  return (
    <section
      id="photos"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* <h2>Fotos</h2> */}
      <div className="photo-gallery">
        {photos.length > 0 ? (
          <>
            {photos.map((photo, index) => (
              <img
                key={index}
                src={photo.url}
                alt={`Foto ${index + 1}`}
                className={`photo-${index + 1}`}
                onClick={() => openModal(index)}
              />
            ))}
          </>
        ) : (
          <p>Nenhuma foto disponível.</p>
        )}
      </div>
      {selectedPhoto && (
        <Modal
          photo={selectedPhoto}
          index={selectedIndex}
          total={photos.length}
          onClose={closeModal}
          onNext={showNextPhoto}
          onPrev={showPrevPhoto}
        />
      )}
    </section>
  );
}

export default Photos;
