import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/firebaseConfig";

function Events() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const now = Date.now() / 1000;
      // let storedData = sessionStorage.getItem("events");

      // if (storedData) {
      //   setEvents(
      //     JSON.parse(storedData).filter((event) => event.date.seconds > now)
      //   );
      //   return;
      // }

      try {
        const eventsCollection = collection(db, "eventos");
        const querySnapshot = await getDocs(eventsCollection);
        const eventsData = querySnapshot.docs.map((doc) => doc.data());
        const futureEvents = eventsData.filter(
          (event) => event.date.seconds > now
        );
        futureEvents.sort((a, b) => a.date.seconds - b.date.seconds);
        setEvents(futureEvents);
        // sessionStorage.setItem("events", JSON.stringify(futureEvents));
      } catch (error) {
        console.error("Erro ao buscar eventos:", error);
      }
    };

    fetchEvents();
  }, []);

  const formatDate = ({ seconds, nanoseconds }) => {
    const date = new Date(seconds * 1000 + nanoseconds / 1000000);
    const day = date.getDate().toString().padStart(2, "0");
    const months = [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ];
    const month = months[date.getMonth()];
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return { day, month, time: `${hours}:${minutes}` };
  };

  return (
    <section className="events-container">
      {events.length > 0 ? (
        events.map((event, index) => {
          const { day, month, time } = formatDate(event.date);
          return (
            <>
              <div
                key={index}
                className="event"
              >
                <div className="event-date">
                  <div className="month">
                    <span>{month}.</span>
                  </div>
                  <div className="day">
                    <span>{day}</span>
                  </div>
                </div>
                <div className="event-info">
                  <span className="event-title">{event.name}</span>
                  <p>{event.description}</p>
                  <p>
                    <span className="hour">Hora:</span> {time}
                  </p>
                  <p>
                    Saiba mais sobre{" "}
                    <Link
                      to={event.url}
                      target="_blank"
                    >
                      o evento
                    </Link>
                    .
                  </p>
                </div>
              </div>
              <hr />
            </>
          );
        })
      ) : (
        <p className="coming-soon-msg">Em breve!</p>
      )}
    </section>
  );
}

export default Events;
