import { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

function Navbar() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const location = useLocation();

  const scrollToRef = useRef(null);

  useEffect(() => {
    if (scrollToRef.current) {
        scrollToRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [location.pathname]);  

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const handleLinkClick = () => {
    setHamburgerOpen(false);
  };

  const handleButtonClick = () => {
    setHamburgerOpen(false);
    const element = document.getElementById("contato");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const path = location.pathname;
    setActiveItem(path === "/" ? "" : path.substring(1));
  }, [location]);

  return (
    <nav ref={scrollToRef}>
    <div
      className="hamburger"
      onClick={toggleHamburger}
    >
          <div className="burger burger1" />
          <div className="burger burger2" />
          <div className="burger burger3" />   
    </div>
    <div className={`navbar-container ${hamburgerOpen ? "open" : ""}`}>
      <NavLink
        to="/"
        className={activeItem === "" ? "active" : ""}
        onClick={handleLinkClick}
      >
        Início
      </NavLink>
      <NavLink
        to="/musicas"
        className={activeItem === "musicas" ? "active" : ""}
        onClick={handleLinkClick}
      >
        Músicas
      </NavLink>
      <NavLink
        to="/fotos-e-videos"
        className={activeItem === "fotos-e-videos" ? "active" : ""}
        onClick={handleLinkClick}
      >
        Vídeos e fotos
      </NavLink>
      <NavLink
        to="/agenda"
        className={activeItem === "agenda" ? "active" : ""}
        onClick={handleLinkClick}
      >
        Agenda
      </NavLink>
      <NavLink
        to="#contato"
        onClick={handleButtonClick}
        className="contato"
      >
        Contato
      </NavLink>
    </div>
  </nav>
  );
}

export default Navbar;
