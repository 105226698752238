import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import fetchSpotifyToken from "../../src/utils/spotifyToken";
import fetchAlbums from "../../src/utils/fetchAlbums";

function Albuns() {
  const [musicData, setMusicData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let storedData = sessionStorage.getItem("spotifyAlbums");

      if (storedData) {
        setMusicData(JSON.parse(storedData));
        setLoading(false);
        return;
      }

      let token = sessionStorage.getItem("spotifyToken");

      if (!token) {
        try {
          token = await fetchSpotifyToken();
        } catch (error) {
          setError("Erro ao obter o token do Spotify.");
          setLoading(false);
          return;
        }
      }

      try {
        const data = await fetchAlbums(token);
        setMusicData(data);
        sessionStorage.setItem("spotifyAlbums", JSON.stringify(data));
      } catch (error) {
        setError("Erro ao obter os álbuns.");
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!musicData) {
    return <div>Erro ao carregar dados de música.</div>;
  }

  return (
    <section id="albuns">
      {/* <h2>Singles e EPs</h2> */}
      <div className="albuns-container">
        {musicData.items.map((album, index) => (
          <Link
            key={index}
            id={album.id}
            to={album.external_urls.spotify}
            target="_blank"
          >
            <img
              className="album-cover"
              src={album.images[1].url}
              alt={album.name}
            />
            <h3 className="album-title">{album.name}</h3>
          </Link>
        ))}
      </div>
    </section>
  );
}

export default Albuns;
