import AdminHeader from "../../components/_adminHeader";
import AdminAgenda from "../../components/_adminAgenda";

function Admin() {
  return (
    <>
      <AdminHeader />
      <AdminAgenda />
    </>
  );
}

export default Admin;
