import { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import lyrics from "../data/lyrics.json";

function Lyrics() {
  const [musicas, setMusicas] = useState([]);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    setMusicas(lyrics);
  }, []);

  const handleAccordionChange = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  return (
    <section id="lyrics">
      {/* <h2>Letras</h2> */}
      <div>
        {musicas.map((musica, index) => (
          <Accordion
            key={index}
            onChange={() => handleAccordionChange(index)}
            id="accordion-item"
          >
            <AccordionSummary
              expandIcon={
                expanded[index] ? (
                  <span className="accordion-btn">
                    <FontAwesomeIcon icon={faMinus} />
                  </span>
                ) : (
                  <span className="accordion-btn">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                )
              }
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography className="music-title">{musica.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-item-content">
              <Typography className="description">
                {musica.lyrics.map((verse, verseIndex) => (
                  <>
                    <div key={verseIndex}>
                      {Object.values(verse).map((line, lineIndex) => (
                        <p key={lineIndex}>{line}</p>
                      ))}
                    </div>
                    <br />
                  </>
                ))}
              </Typography>
              <div className="button-wrapper">
                <Button
                  onClick={() => window.open(musica.url_cifra, "_blank")}
                  className="cifra-btn"
                >
                  Ver cifra
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </section>
  );
}

export default Lyrics;
