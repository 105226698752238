import { useState, useEffect } from "react";
import Videos from "../../components/_videos";
import Photos from "../../components/_photos";
import Streaming from "../../components/_streaming";

import cover from "../../assets/alfandega-3.webp";

function PhotosAndVideos() {
  const [selectedButton, setSelectedButton] = useState("videos");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  const handleButtonClick = (id) => {
    setSelectedButton(id);
  };

  return (
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <section className="hero">
        <div className="cover-container">
          <img src={cover} alt="cover" loading="lazy" />
          <h1 className="page-title">Vídeos e fotos</h1>
        </div>
        <div className="section-btns-container">
          <button
            className={`section-btn ${selectedButton === "videos" ? "selected" : ""}`}
            onClick={() => handleButtonClick("videos")}
          >
            Vídeos
          </button>
          <button
            className={`section-btn ${selectedButton === "photos" ? "selected" : ""}`}
            onClick={() => handleButtonClick("photos")}
          >
            Fotos
          </button>
        </div>
      </section>
      {selectedButton === "videos" && <Videos />}
      {selectedButton === "photos" && <Photos />}
      <Streaming />
    </div>
  );
}

export default PhotosAndVideos;
