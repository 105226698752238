import { useState, useEffect } from "react";
import { db } from "../../src/services/firebaseConfig";
import {
  collection,
  addDoc,
  Timestamp,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function AdminAgenda() {
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    //Isso valida o formulario para poder habilitar ou nao o botao de envio
    const checkFormValidity = () => {
      if (eventName && eventDate && eventDetails && eventLink) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    };
    checkFormValidity();
    fetchEvents();
  }, [eventName, eventDate, eventDetails, eventLink]);

  const fetchEvents = async () => {
    const eventsCollection = collection(db, "eventos");
    const eventsSnapshot = await getDocs(eventsCollection);
    const eventsList = [];
    eventsSnapshot.forEach((doc) => {
      eventsList.push({ id: doc.id, ...doc.data() });
    });
    setEvents(eventsList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const eventTimestamp = Timestamp.fromDate(new Date(eventDate));

    const eventData = {
      date: eventTimestamp,
      description: eventDetails,
      name: eventName,
      url: eventLink,
    };

    try {
      const docRef = await addDoc(collection(db, "eventos"), eventData);
      console.log("Document written with ID: ", docRef.id);
      fetchEvents();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      await deleteDoc(doc(db, "eventos", eventId));
      fetchEvents(); // Atualiza a lista de eventos após deletar um evento
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };

  return (
    <section id="admin-agenda">
      <div className="admin-agenda">
        <h2>Cadastrar novo evento</h2>
        <form
          className="form-agenda-container"
          onSubmit={handleSubmit}
        >
          <label htmlFor="event-name">
            Qual é o nome do evento?
            <input
              id="event-name"
              type="text"
              placeholder="Nome do evento"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              required
            />
          </label>
          <label htmlFor="event-date-time">
            Que dia e que hora vai rolar?
            <input
              id="event-date-time"
              type="datetime-local"
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              required
            />
          </label>
          <label htmlFor="event-details">
            Conta mais detalhes de como vai ser?
            <textarea
              id="event-details"
              placeholder="Ex: Será uma festa temática, traga seus trajes de época."
              value={eventDetails}
              onChange={(e) => setEventDetails(e.target.value)}
              required
            />
          </label>
          <label htmlFor="event-link">
            Aqui vai o link para o evento. Precisa colocar como o que aparece no
            exemplo, com o https:// e tudo, bem completinho...
            <input
              id="event-link"
              type="url"
              placeholder="Ex: https://www.instagram.com/evento"
              value={eventLink}
              onChange={(e) => setEventLink(e.target.value)}
              required
            />
          </label>
          <button
            type="submit"
            className="add-event-btn"
            disabled={!isFormValid}
          >
            Cadastrar Evento
          </button>
        </form>
        <div className="events-list">
          <h3>Aqui tem uma listinha de todos os eventos já cadastrados</h3>
          <p className="list-description">
            Poder só apagar. Se quiser cadastrar um novo usa o form acima. Se
            quiser alterar, precisa deletar e criar outro.
          </p>
          <p className="list-description">
            <span>Cuidado: </span>clicou na lixeira = deletou evento. Apagou por
            engano? Só lamento! Vai precisar cadastrar de novo.
          </p>
          <ul>
            {events.map((event) => (
              <li key={event.id}>
                <p className="base-event-name">
                  {event.name}
                  <span>{event.date.toDate().toLocaleString()}</span>
                </p>
                <button onClick={() => handleDeleteEvent(event.id)}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="trash-icon"
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default AdminAgenda;
