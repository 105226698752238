function Videos() {
  const videos = [
    {
      iframe:
        '<iframe width="560" height="315" src="https://www.youtube.com/embed/DEw5RjRAp14?si=cRf7G-R3CR5fBOBi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    },
    {
      iframe:
        '<iframe width="560" height="315" src="https://www.youtube.com/embed/0HZ8I8CkEy8?si=RNEYji8uPodTOOS6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    },
    {
      iframe:
        '<iframe width="560" height="315" src="https://www.youtube.com/embed/OYxQP-Kmkts?si=tdOFwfrzbKmyy52s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    },
  ];

  return (
    <section id="videos">
      {/* <h2>Vídeos</h2> */}
      {videos.map((video, index) => (
        <div
          className="video-container"
          key={index}
        >
          <div
            dangerouslySetInnerHTML={{ __html: video.iframe }}
            className="iframe-wrapper"
          ></div>
        </div>
      ))}
    </section>
  );
}

export default Videos;
