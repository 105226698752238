import { useState, useEffect } from "react";
import Albuns from "../../components/_albuns";
import Lyrics from "../../components/_lyrics";
import Streaming from "../../components/_streaming";
import fetchSpotifyToken from "../../utils/spotifyToken";

import cover from "../../assets/alfandega-3.webp";

function Music() {
  const [selectedButton, setSelectedButton] = useState("albuns");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchSpotifyToken();
  }, []);

  const handleButtonClick = (id) => {
    setSelectedButton(id);
  };

  return (
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <section className="hero">
        <div className="cover-container">
          <img src={cover} alt="cover" loading="lazy" />
          <h1 className="page-title">Músicas</h1>
        </div>
        <div className="section-btns-container">
          <button
            className={`section-btn ${selectedButton === "albuns" ? "selected" : ""}`}
            onClick={() => handleButtonClick("albuns")}
          >
            Singles e EPs
          </button>
          <button
            className={`section-btn ${selectedButton === "lyrics" ? "selected" : ""}`}
            onClick={() => handleButtonClick("lyrics")}
          >
            Letras
          </button>
        </div>
      </section>
      {selectedButton === "albuns" && <Albuns />}
      {selectedButton === "lyrics" && <Lyrics />}
      <Streaming />
    </div>
  );
}

export default Music;
