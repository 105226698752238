import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      setUid(user.uid);
      sessionStorage.setItem("user", user.uid);
    } catch (error) {
      setError(
        "Credenciais incorretas. Por favor, verifique seu e-mail e senha."
      );
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      navigate("/admin");
    }
  }, [navigate]);
  
  useEffect(() => {
    if (uid) {
      window.location.href = "/admin";
    }
  }, [uid, navigate]);

  return (
    <>
      <div className="form-container">
        <h1>Login</h1>
        <div>
          <input
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button onClick={handleLogin} className="login-btn">Login</button>
        {error && <p className="error-msg">{error}</p>}
        <button onClick={() => navigate("/")} className="back-btn">Voltar</button>
      </div>
    </>
  );
}

export default Login;
