import { useNavigate } from "react-router-dom";

function AdminHeader() {
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.clear("user");
    navigate("/");
  };

  return (
    <section id="admin-header">
      <div className="admin-header">
        <h1 className="admin-title">Oi Júlia!</h1>
        <button onClick={logout} className="logout-btn">Logout</button>
      </div>
      <div className="admin-msg">
        <p>Aqui nessa página tu pode alterar algumas coisas.</p>
        <p><span>Dica bafo: </span>Pra ver a alteração aplicada no site, precisa recarregar a página onde foi feita a mudança, assim o site vai buscar de novo as infos na base.</p>
      </div>
    </section>
  );
}

export default AdminHeader;
