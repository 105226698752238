import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/routes/_protectedRoute";

import Layout from "./components/layout/_layout";
import Home from "./pages/Home";
import PhotosAndVideos from "./pages/FotosEVideos";
import Music from "./pages/Musicas";
import Agenda from "./pages/Agenda";
import LoginPage from "./pages/Login";
import Admin from "./pages/Admin";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          index
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/musicas"
          element={
            <Layout>
              <Music />
            </Layout>
          }
        />
        <Route
          path="/fotos-e-videos"
          element={
            <Layout>
              <PhotosAndVideos />
            </Layout>
          }
        />
        <Route
          path="/agenda"
          element={
            <Layout>
              <Agenda />
            </Layout>
          }
        />
        <Route
          path="/login"
          element={<LoginPage />}
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
