import { useState, useEffect } from "react";
import Bio from "../../components/_bio";
import Streaming from "../../components/_streaming";

import coverSmall from "../../assets/cover-small.webp";
import coverMedium from "../../assets/cover-medium.webp";
import coverLarge from "../../assets/cover-large.webp";

function Home() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  //breaking points para renderizar imgs diferentes no cover em funcao do dispositivo
  const smallBreakpoint = 480; 
  const mediumBreakpoint = 768; 

  let coverSrc;
  if (window.innerWidth < smallBreakpoint) {
    coverSrc = coverSmall;
  } else if (window.innerWidth < mediumBreakpoint) {
    coverSrc = coverMedium;
  } else {
    coverSrc = coverLarge;
  }

  return (
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <section className="hero-home">
        <div className="home-cover-container">
          <img
            src={coverSrc}
            alt="cover"
          />
          <h1 className="page-title">Júlia Peixotto</h1>
        </div>
      </section>
      <Bio />
      <Streaming />
    </div>
  );
}

export default Home;
