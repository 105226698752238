const artistId = "086pNwfXYOvjy5uUP0jWiU";

async function fetchAlbums() {
  try {
    const token = sessionStorage.getItem("spotifyToken");
    const response = await fetch(
      `https://api.spotify.com/v1/artists/${artistId}/albums`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errorMessage = `Erro ${response.status}: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    const data = await response.json();
    //sessionStorage.setItem("albuns", JSON.stringify(data));

    return data;
  } catch (error) {
    console.error("Erro ao obter os álbuns do artista:", error.message);
    throw error;
  }
}

export default fetchAlbums;
