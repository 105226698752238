import { Link } from "react-router-dom";

import spotifyLogo from "../assets/logos/Spotify_Logo_RGB_Green.png";
import youtubeLogo from "../assets/logos/YouTube_Logo.png";
import soundcloudLogo from "../assets/logos/SoundCloud_logo.png";
import deezerLogo from "../assets/logos/Logo_Deezer_2023.png";
import appleMusicLogo from "../assets/logos/Apple_Music_logo.png";
import youtubeMusicLogo from "../assets/logos/youtube_music_icon.png";

function Streaming() {
  return (
    <section className="streaming-container">
      <h2>Onde ouvir</h2>
      <p>As principais plataformas onde você pode ouvir minha música:</p>
      <div className="streaming-links">
        <Link
          to={"https://open.spotify.com/intl-fr/artist/086pNwfXYOvjy5uUP0jWiU"}
          target="_blank"
        >
          <img
            src={spotifyLogo}
            alt="spotifyLogo"
          />
        </Link>
        <Link
          to={"https://deezer.page.link/2x2XS4JuM1VKKDBa7"}
          target="_blank"
        >
          <img
            src={deezerLogo}
            alt="deezerLogo"
          />
        </Link>
        <Link
          to={"https://music.apple.com/br/artist/j%C3%BAlia-peixotto/1434997378"}
          target="_blank"
        >
          <img
            src={appleMusicLogo}
            alt="appleMusicLogo"
          />
        </Link>
        <Link
          to={"https://www.youtube.com/channel/UCwyMlj619t9yeLSdJEnFJlw"}
          target="_blank"
        >
          <img
            src={youtubeLogo}
            alt="youtubeLogo"
          />
        </Link>
        <Link
          to={"https://music.youtube.com/channel/UCKZrxzCY4yRb4GqoiKHkDGQ"}
          target="_blank"
        >
          <img
            src={youtubeMusicLogo}
            alt="youtubeMusicLogo"
          />
        </Link>
        <Link
          to={"https://soundcloud.com/juliapeixotto"}
          target="_blank"
        >
          <img
            src={soundcloudLogo}
            alt="soundcloudLogo"
          />
        </Link>
      </div>
    </section>
  );
}

export default Streaming;
