import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const today = new Date();

  return (
    <>
      <footer id="contato">
        <div className="footer-content">
          <div className="footer-logo">
            <span>
            Júlia <br />
            Peixotto
            </span>
          </div>
          <div className="footer-links">
            <div className="infos-contact">
              <h3>Contato</h3>
              <Link to="mailto:juliacarolinap@gmail.com">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="footer-icon"
                />
                contatojuliapeixotto@gmail.com
              </Link>
              <Link
                to="https://www.instagram.com/julia_peixotto/"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="footer-icon"
                />
                julia_peixotto
              </Link>
              <Link to="https://www.facebook.com/jlpeixotto">
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="footer-icon"
                />
                jlpeixotto
              </Link>
            </div>
            <div className="quick-links">
              <h3>Quick links</h3>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/musicas">Músicas</NavLink>
              <NavLink to="/fotos-e-videos">Fotos e vídeos</NavLink>
              <NavLink to="/agenda">Agenda</NavLink>
            </div>
          </div>
        </div>
        <div className="copyright">
          <span>
            ©{today.getFullYear()} All rights reserved (Website designed and
            developed by
            <a
              href="https://augusto-mattos.vercel.app/"
              rel="noreferrer"
              target="_blank"
            >
              Augusto Mattos
            </a>
          )
          </span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
