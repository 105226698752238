import Login from "../../components/_login";

function LoginPage() {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginPage;
