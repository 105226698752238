import { useState, useEffect } from "react";
import Events from "../../components/_events";
import Streaming from "../../components/_streaming";

import cover from "../../assets/alfandega-3.webp";

function Agenda() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <section className="hero">
        <div className="cover-container">
          <img
            src={cover}
            alt="cover"
            loading="lazy"
          />
          <h1 className="page-title">Agenda</h1>
        </div>
        <div className="section-btns-container">
          <div className="section-btn selected agenda">Próximos eventos</div>
        </div>
      </section>
      <Events />
      <Streaming />
    </div>
  );
}

export default Agenda;
