import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function Modal({ photo, index, total, onClose, onNext, onPrev }) {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose();
      } else if (e.key === "ArrowRight") {
        onNext();
      } else if (e.key === "ArrowLeft") {
        onPrev();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, onNext, onPrev]);

  return (
    <div
      className="modal-backdrop"
      onClick={onClose}
    >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={photo}
          alt={`Foto ${index + 1}`}
          className="modal-photo"
        />
        <button
          className="modal-close"
          onClick={onClose}
        >
          ×
        </button>
        {index > 0 && (
          <button
            className="modal-prev"
            onClick={onPrev}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        )}
        {index < total - 1 && (
          <button
            className="modal-next"
            onClick={onNext}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        )}
      </div>
    </div>
  );
}

export default Modal;
